/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

* {
  font-family: 'Montserrat', sans-serif;
}

html {
  font-size: 17px;
  min-height: 100%;
  overflow-x: hidden;
  position: relative;
}

body {
  height: 100%;
  margin: 0;
  min-height: 100%;
  overflow: hidden;
}

body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
